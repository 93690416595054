<template>
  <div>
    <el-drawer
      title="测试"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="40%"
    >
      <tree-transfer
        :title="title"
        :node_key="nodeKey"
        :from_data="fromData"
        :to_data="toData"
        :defaultProps="{
            label:'name',
            id: 'code',
        }"
        @add-btn="add"
        @remove-btn="remove"
        :mode="mode"
        height="700px"
        filter
        openAll
      ></tree-transfer>
      <el-button @click="testTu">测试</el-button>
    </el-drawer>
  </div>
</template>
<script>
import { getTreeMenuApi } from "@/api/system/systemCommon/menu.js";
import treeTransfer from "el-tree-transfer";
import { addRoleMenuApi } from "@/api/system/role/role.js";
export default {
  components: { treeTransfer },
  // 注册
  props: {
    // el-tree node-key 必须唯一
    node_key: {
      type: String,
      default: "code"
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      addEditForm: {},
      timer: null,
      nodeKey: "code",
      optType: 1,
      title: ["待选", "已选"], //标题 类型：Array 必填：false 默认：["源列表", "目标列表"]
      mode: "transfer", //设置模式，字段可选值为transfer|addressList 类型：String 必填：false 补充：mode默认为transfer模式，即树形穿梭框模式，可配置字段为addressList改为通讯录模式，通讯录模式时按钮不可自定义名字，如要自定义标题名在title数组传入四个值即可，addressList模式时标题默认为通讯录、收件人、抄送人、密送人
      fromData: [], //源数据 类型：Array 必填：true 补充：数据格式同element-ui tree组件，但必须有id和pid
      toData: [],
      roleCode: ""
    };
  },
  methods: {
    show(code, type) {
      this.dialog = true;
      this.optType = type;
      this.roleCode = code;
      this.getAddress();
      this.noTreeMenuButton(code);
      this.getTreeMenuButton(code);
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.fromData=[],
      this.toData=[]
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode() {
      if (this.mode == "transfer") {
        this.mode = "addressList";
      } else {
        this.mode = "transfer";
      }
    },
    // 监听穿梭框组件添加
    add(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("fromData:", fromData);
      console.log("toData:", toData);
      console.log("obj:", obj);
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("fromData:", fromData);
      console.log("toData:", toData);
      console.log("obj:", obj);
    },
    testTu() {
      console.log(this.fromData);
      console.log(this.toData);

      addRoleMenuApi({roleCode:this.roleCode,menuList:this.toData}).then(res=>{
            this.cancelForm();
      })
    },

    getTreeMenuButton(code) {
      getTreeMenuApi({ roleCode: code, type: 1 }).then(res => {
        this.toData = res.result;
        this.toData.forEach(item => {
          //el-tree-transfer组件的第一个pid必须为0
          item.pid = 0;
        });
      });
    },
    noTreeMenuButton(code) {
      //没有选择的
      getTreeMenuApi({ roleCode: code, type: 2 }).then(res => {
        console.log(res.result);
        this.fromData = res.result;
        this.fromData.forEach(item => {
          //el-tree-transfer组件的第一个pid必须为0
          item.pid = 0;
        });
      });
    },
    getAddress() {
      this.fromData.forEach(item => {
        //el-tree-transfer组件的第一个pid必须为0
        item.pid = 0;
      });
      this.toData.forEach(item => {
        //el-tree-transfer组件的第一个pid必须为0
        item.pid = 0;
      });
    }
  }
};
</script>
<style scoped>
</style>